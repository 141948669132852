.button, button {
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  border: unset;
  display: inline-block;
  white-space: nowrap;
  transition-property: background, color;
  transition-duration: var(--default-timing);
  transition-timing-function: var(--default-transition-function);
  text-align: center;
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 125%;

  &_white {
    padding: 15px 34px;
    background: #fff;
    color: var(--black-color);
  }

  &_violet {
    background: var(--violet);
    color: #fff;
  }

  &_yellow {
    padding: 10px 20px;
    background: var(--yellow);
  }
}

@include respond-up('s-large') {
  .button {
    &_white {
      &:hover {
        background: #604DC2;
        color: #fff;
      }
    }

    &_violet {
      &:hover {
        background: #604DC2;
      }
    }

    &_yellow {
      &:hover {
        background: #FFD02C;
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    &_violet {
      padding: 15px 34px;
    }
  }
}

@include respond-down('small') {
  .button {
    &_violet {
      padding: 13px 34px;
    }
  }
}