.recall-form {
  background: var(--grey-background);
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
  }

  &:before {
    background: radial-gradient(50% 50% at 50% 50%, #C8C0F3 0%, rgba(200, 192, 243, 0) 99.34%);
    z-index: 15;
  }

  &:after {
    background: radial-gradient(45.63% 47.98% at 50% 50%, #CAD6F5 0%, rgba(197, 219, 235, 0.885417) 3.65%, rgba(197, 219, 235, 0) 100%);
    z-index: 10;
  }

  &__fields {
    display: flex;
  }

  &__top {
    + div button[type="submit"] {
      display: none;
    }
  }

  &__policy-wrap {
    display: flex;
  }

  &__policy {
    display: flex;
    column-gap: 10px;
    align-items: baseline;
  }

  &__policy-input {
    transform: translateY(3px);
  }

  &__icon-wrap, &__form-wrap {
    z-index: 20;
  }

  &__success-title-wrap {
    display: flex;
    flex-direction: column;
  }

  &__success-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
  }
}

@include respond-up('s-large') {
  .recall-form {
    padding: 40px 0;

    &_bottom {
      padding: 50px 0;
      margin-bottom: -44px;
    }

    &_first-gradient {
      &:before {
        width: 1364px;
        height: 1039px;
        left: -453px;
        top: -144px;
      }

      &:after {
        width: 1040px;
        height: 992px;
        right: -351px;
        top: -120px;
      }

      .recall-form {
        &__container {
          &:before {
            width: 1486px;
            height: 1308px;
            bottom: -110px;
            right: -295px;
          }
        }
      }
    }

    &_second-gradient {
      &:before {
        width: 1364px;
        height: 1039px;
        right: -8px;
        top: -78px;
      }

      &:after {
        width: 1486px;
        height: 1308px;
        left: 595px;
        bottom: -126px;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: var(--grid-column8) calc(var(--grid-column4) + var(--grid-gap));
    }

    &__form-wrap {
      grid-column: 1/2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__icon-wrap {
      grid-column: 2/3;
    }

    &__fields {
      column-gap: var(--grid-gap);
    }

    &__field {
      width: 100%;
    }

    &__policy-wrap {
      justify-content: space-between;
      margin-top: 40px;
      align-items: flex-start;
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    border-radius: 50px;
    position: relative;

    &_first-gradient {
      .recall-form {
        &__container {
          &:before {
            position: absolute;
            content: '';
            background: radial-gradient(45.63% 47.98% at 50% 50%, #CAD6F5 0%, rgba(197, 219, 235, 0.885417) 3.65%, rgba(197, 219, 235, 0) 100%);
            border-radius: 50%;
          }
        }
      }
    }

    &__fields {
      column-gap: var(--grid-gap);
      margin-top: 35px;
    }

    &__success-title-wrap {
      margin-top: 55px;
    }

    &__success-subtitle {
      margin-top: 25px;
    }

    &__success-icon-wrap {
      width: 130px;
      height: 130px;
      box-shadow: 0 10px 20px rgba(201, 200, 217, 0.15);
    }
  }
}

@include respond('medium') {
  .recall-form {
    padding: 50px 0 50px;

    &__title {
      margin-right: calc(-1.5 * var(--grid-column1));
    }

    &_bottom {
      margin-bottom: -50px;
    }

    &_first-gradient {
      &:before {
        width: 1364px;
        height: 1039px;
        right: -76px;
        top: -144px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }

      &:after {
        width: 1040px;
        height: 992px;
        left: 811px;
        top: -120px;
      }

      .recall-form {
        &__container {
          &:before {
            width: 1486px;
            height: 1308px;
            bottom: -62px;
            left: -309px;
          }
        }
      }
    }

    &_second-gradient {
      &:before {
        height: 1039px;
        width: 1364px;
        right: -76px;
        top: -144px;
      }

      &:after {
        width: 1486px;
        height: 1308px;
        left: 309px;
        bottom: -62px;
      }
    }

    &__container {
      display: grid;
      grid-template-columns: var(--grid-column6) var(--grid-column6);
      grid-column-gap: var(--grid-gap);
    }

    &__fields {
      row-gap: 16px;
      width: var(--grid-column6);
    }

    &__icon-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      svg {
        margin-right: -30px;
        margin-bottom: -10px;
        width: 350px;
        height: auto;
      }
    }

    &__policy-wrap {
      flex-direction: column;
      margin-top: 30px;
    }

    &__submit {
      margin-top: 30px;
      width: fit-content;
      align-self: flex-end;
    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &__fields {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    padding: 40px 0 5px;
    border-radius: 20px;

    &_bottom {
      margin-bottom: -40px;
    }

    &_first-gradient {
      &:before {
        width: 913px;
        height: 886px;
        right: -55px;
        bottom: 240px;
      }

      &:after {
        width: 889px;
        height: 848px;
        left: -199px;
        top: 76px;
      }
    }

    &_second-gradient {
      &:before {
        width: 913px;
        height: 886px;
        right: -88px;
        top: 194px;
      }

      &:after {
        width: 889px;
        height: 848px;
        left: -199px;
        bottom: 76px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
    }

    &__fields {
      margin-top: 25px;
      row-gap: 10px;
    }

    &__policy-wrap {
      margin-top: 17px;
      flex-direction: column-reverse;
    }

    &__policy {
      margin-top: 18px;
    }

    &__icon-wrap {
      margin-top: 5px;

      svg {
        margin-right: -20px;
        margin-bottom: -10px;
        width: 354px;
        height: auto;
      }
    }

    &__success-title-wrap {
      margin-top: 35px;
    }

    &__success-subtitle {
      margin-top: 20px;
    }

    &__success-icon-wrap {
      width: 90px;
      height: 90px;
      box-shadow: 0 6.92308px 13.8462px rgba(201, 200, 217, 0.15);

      svg {
        width: 38px;
        height: 30px;
      }
    }
  }
}