.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh
}

@include respond-up('medium') {
  .layout {
    --header-height: 54px;
  }
}

@include respond-down('small') {
  .layout {
    --header-height: 52px;
  }
}