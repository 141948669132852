%text-main {
  /* Main text */
  font-size: var(--font-size);
  font-weight: 400;
  line-height: 140%;
}

@include respond-up("s-large") {
  %text-main {
    --font-size: 18px;
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 16px;
  }
}