.accordion {
  &__title {
    display: flex;
    justify-content: center;
  }

  &__container {
    background: var(--grey-background);
    border-radius: 20px;
  }

  &__element {
    overflow: hidden;

    &:not(:last-child) {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        background: var(--stroke);
        bottom: 0;
        transition: opacity var(--default-timing) var(--default-transition-function);
      }
    }

    &._opened {
      &:before {
        opacity: 0;
      }

      .accordion {
        &__link {
          color: var(--violet);
        }

        &__plus {
          &:after {
            transform: translateX(-50%) rotate(90deg);
          }
        }
      }
    }
  }

  &__link {
    transition: color var(--default-timing) var(--default-transition-function);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__plus {
    display: flex;
    width: 16px;
    height: 16px;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      background: var(--violet);
      transform-origin: center;
      transition: transform var(--default-timing) var(--default-transition-function);
    }

    &:before {
      width: 100%;
      height: 2px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      width: 2px;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  &__block {
    height: 0;
    transition: height var(--default-timing) var(--default-transition-function);
  }
}

@include respond-up('s-large') {
  .accordion {
    &__container {
      margin-top: 36px;
    }

    &__block {
      width: calc(var(--grid-column10) - 40px);
    }
  }
}

@include respond-up('medium') {
  .accordion {
    &__link {
      padding: 28px 40px;
      align-items: center;
    }

    &__element {
      &:not(:last-child) {
        &:before {
          width: calc(100% - 80px);
          left: 40px;
        }
      }

      &:first-child {
        .accordion {
          &__link {
            padding-top: 50px;
          }
        }
      }

      &:last-child {
        .accordion {
          &__link {
            padding-bottom: 50px;
          }
        }
      }
    }

    &__block {
      padding-left: 40px;
    }

    &__text-wrap {
      padding-bottom: 22px;
    }
  }
}

@include respond('medium') {
  .accordion {
    &__container {
      margin-top: 25px;
    }

    &__block {
      width: calc(var(--grid-column10) + var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .accordion {

  }
}

@include respond-down('small') {
  .accordion {
    &__link {
      padding: 18px 25px;
      align-items: flex-start;
    }

    &__element {
      &:not(:last-child) {
        &:before {
          width: calc(100% - 50px);
          left: 25px;
        }
      }

      &:first-child {
        .accordion {
          &__link {
            padding-top: 25px;
          }
        }
      }

      &:last-child {
        .accordion {
          &__link {
            padding-bottom: 25px;
          }
        }
      }
    }

    &__container {
      margin-top: 22px;
    }

    &__link-text {
      width: calc(var(--grid-column7) + var(--grid-gap) * 2 + 15px);
    }

    &__plus {
      transform: translateY(5px);
    }

    &__block {
      padding: 0 25px;
    }

    &__text-wrap {
      padding-bottom: 12px;
    }
  }
}