.floating-field {
  position: relative;
  height: var(--default-input-height);

  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: opacity var(--default-timing) var(--default-transition-function);
    margin: 0;
  }

  &._filled input + label,
  input:focus + label {
    opacity: 0;
  }

  & > input[type="text"], & > input[type="password"], & > input[type="search"] {
    &:-internal-autofill-selected {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
  }
}