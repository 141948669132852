.index-page {
  &__grey-background {
    background: var(--grey-background);
  }
}

@include respond-up('s-large') {
  .index-page {
    &__grey-background {
      padding-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .index-page {
    &__grey-background {
      border-radius: 50px;
    }
  }
}

@include respond('medium') {
  .index-page {
    &__grey-background {
      padding-top: 80px;
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__grey-background {
      border-radius: 20px 20px 30px 30px;
      padding-top: 40px;
    }
  }
}