.request-form {
  position: relative;

  &__data {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__success {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: var(--grid-spacer-and-indent);
    right: 0;
    bottom: 0;
    top: 0;
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    z-index: 100;
  }

  &.success {
    .request-form {
      &__data,
      &__title {
        opacity: 0;
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        height: min-content;
      }
    }
  }
}

@include respond-up('s-large') {
  .request-form {
    &__success {
      top: 83px;
      width: var(--grid-column8);
    }
  }
}

@include respond('medium') {
  .request-form {
    &__success {
      top: 50px;
      width: var(--grid-column7);
    }
  }
}

@include respond-down('small') {
  .request-form {
    &__success {
      top: 40px;
    }
  }
}