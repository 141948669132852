/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1199
  ),
  s-large: (
    from: 1200,
    to: 1599
  ),
  large: (
    from: 1600,
    to: 1920
  ),
  x-large: (
    from: 1921
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Manrope', sans-serif;
  --h-font: 'Manrope', sans-serif;

  --font-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79,
  'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;

  --t: transparent;

  --grey-text: #545455;
  --black-color: #010000;
  --violet: #8D7CE6;
  --violet-light: #B7BEFD;
  --violet-super-light: #EEEBFB;
  --stroke: #D2D4D7;
  --red: #F20000;
  --yellow: #FFC120;
  --grey-background: #F9F9F9;

  --violet-pale: #C4D2FC;
  --white-80: rgba(255, 255, 255, 0.8);

  --stroke-border-style: 1px solid var(--stroke);
  --box-shadow: 0px 10px 40px rgba(175, 174, 180, 0.45);

  --primary-color: black;
  --background-color: #f0f0f0; // серая подлога
  --alert-color: #FF6969;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity-40: rgba(0, 0, 0, .4);
  --primary-color-opacity: rgba(0, 0, 0, .85);

  --default-timing: .4s;
  --long-timing: .8s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);
}
