h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-style: normal;
}

h1, .h1, h2, .h2 {
  font-weight: 600;
}

h1, .h1, h3, .h3, h4, .h4 {
  line-height: 125%;
}

h2, .h2 {
  line-height: 120%;
}

h3, .h3, h4, .h4 {
  font-weight: 500;
}

@include respond-up("s-large") {
  h1, .h1 {
    --h-size: 50px;
  }

  h2, .h2 {
    --h-size: 42px;
  }

  h3, .h3 {
    --h-size: 28px;
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond('medium') {
  h1, .h1 {
    --h-size: 36px;
  }

  h2, .h2 {
    --h-size: 36px;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 28px;
  }

  h2, .h2 {
    --h-size: 24px;
  }

  h3, .h3 {
    --h-size: 22px;
  }

  h4, .h4 {
    --h-size: 20px;
  }

  h5, .h5 {
    --h-size: 14px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}
