@include respond-up('s-large') {
  .block {
    margin-top: 120px;
  }
}

@include respond('medium') {
  .block {
    margin-top: 100px;
    &_small {
      margin-top: 80px;
    }
  }
}

@include respond-down('small') {
  .block {
    margin-top: 50px;
    &_small {
      margin-top: 40px;
    }
  }
}