.hero-block {
  position: relative;
  overflow: hidden;

  &__wrap {
    position: relative;
    background: var(--violet);
  }

  &__container {
    height: 100%;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    z-index: 20;
    position: relative;
  }

  &__line {
    position: absolute;
    z-index: 10;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-container {
    position: absolute;
    z-index: 10;
    bottom: 0;
  }

  &__image-wrap {
    position: relative;
    padding-bottom: calc(656 / 484 * 100%);
  }

  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

@include respond-up('s-large') {
  .hero-block {
    height: 788px;

    &__text-wrap {
      padding-top: 163px;
    }

    &__wrap {
      height: 688px;
    }

    &__text {
      width: var(--grid-column6);
    }

    &__image-container {
      width: var(--grid-column5);
      right: var(--grid-spacer-and-indent);
    }

    &__line {
      &_first {
        top: 6px;
        right: calc(var(--grid-spacer-and-indent) + var(--grid-column2) + var(--grid-gap) + var(--grid-column) / 2);
      }

      &_second {
        right: 0;
        top: 154px;
      }
    }

    &__arrow-wrap {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
    }

    &__arrow {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(202.65deg, #CBE7FD -10.87%, #B5A7FA 93.38%);
      border-radius: 50px;
      position: relative;
      z-index: 20;

      &:before {
        position: absolute;
        content: '';
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        animation: arrow-circle 2s infinite normal cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
      }
    }
  }
}

@include respond-up('medium') {
  .hero-block {
    &__wrap {
      border-radius: 0 0 50px 50px;
    }

    &__button-wrap {
      margin-top: 35px;
    }

    &__text {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .hero-block {
    height: 718px;

    &__wrap {
      height: 618px;
    }

    &__text-wrap {
      padding-top: 100px;
      width: var(--grid-column7);
    }

    &__text {
      width: var(--grid-column6);
    }

    &__image-container {
      width: calc(var(--grid-column8) - var(--grid-column) / 2);
      max-width: 564px;
      right: -44px;
    }

    &__line {
      &_first {
        top: calc(-1 * var(--header-height) + 4px);
        right: calc(var(--grid-spacer-and-indent) + var(--grid-column2) + var(--grid-gap) + var(--grid-column) / 2);

        svg {
          width: 307px;
          height: auto;
        }
      }

      &_second {
        right: -46px;
        top: 84px;

        svg {
          width: 223px;
          height: auto;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .hero-block {
    &__wrap {
      overflow: hidden;
    }

    &__arrow {
      display: none;
    }
  }
}

@include respond-down('small') {
  .hero-block {
    height: 775px;

    &__wrap {
      height: 618px;
    }

    &__wrap {
      border-radius: 0 0 20px 20px;
    }

    &__text-wrap {
      padding-top: 37px;
    }

    &__image-container {
      width: var(--grid-column10);
      max-width: 370px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 157% / 3.34;
    }

    &__line {
      &_first {
        left: -91px;
        bottom: 70px;

        svg {
          width: 311px;
          height: auto;
        }
      }

      &_second {
        right: -38px;
        bottom: 75px;

        svg {
          width: 166px;
          height: auto;
        }
      }
    }

    &__button-wrap {
      margin-top: 25px;
    }

    &__text {
      margin-top: 16px;
    }
  }
}

@keyframes arrow-circle {
  0% {
    opacity: 0.15;
    height: 60px;
    width: 60px;
  }

  50% {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #C4C4C4;
  }

  100% {
    width: 106px;
    height: 106px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #C4C4C4;
    opacity: 0;
  }
}