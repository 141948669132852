.text {
  font-family: var(--font);
  font-style: normal;
  color: var(--black-color);
  transition: color var(--default-timing) var(--default-transition-function);

  &_black {
    color: var(--black-color);
  }

  &_spacing {
    letter-spacing: -0.02em;
  }

  &_white {
    color: #fff;
  }

  &_white-80 {
    color: var(--white-80);
  }

  &_violet-pale {
    color: var(--violet-pale);
  }

  &_grey {
    color: var(--grey-text);
  }

  &_violet {
    color: var(--violet);
  }

  &_red {
    color: var(--red);
  }

  &__main {
    @extend %text-main;
  }

  &__sixteen {
    @extend %text-sixteen;
  }

  // стиль для:
  // хедера, текста внутри поля в конфигураторе,
  // мелкий текст подсказка в блоке с кодом,
  // текст радио с позицией кнопки
  &__fifteen {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
  }

  &__rates-title {
    font-weight: 500;
    line-height: 125%;
  }

  &__field-label,
  &__size {
    font-size: 12px;
    line-height: 125%;
  }

  &__field-label {
    font-weight: 400;
  }

  &__size {
    font-weight: 500;
  }

  &__size-radio,
  &__size-preview {
    font-size: 16px;
  }

  &__size-radio {
    font-weight: 400;
    line-height: 120%;
  }

  &__size-preview {
    font-weight: 500;
    line-height: 125%;
  }

  &__form-field {
    font-weight: 500;
    line-height: 145%;
  }

  &__form-policy {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }

  &__form-error {
    font-weight: 400;
    font-size: 8px;
    line-height: 125%;
  }

  &__accordion {
    font-weight: 500;
    line-height: 130%;
  }

  &__footer-love {
    font-weight: 500;
  }

  &__footer-policy {
    font-weight: 500;
    line-height: 145%;
  }
}

@include respond-up('medium') {
  .text {
    &__rates-title {
      font-size: 26px;
    }

    &__form-field {
      font-size: 18px;
    }

    &__accordion {
      font-size: 22px;
    }

    &__footer-love {
      font-size: 18px;
      line-height: 140%;
    }

    &__footer-policy {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .text {
    &__rates-title {
      font-size: 20px;
    }

    &__form-field {
      font-size: 16px;
    }

    &__accordion {
      font-size: 18px;
    }

    &__footer-love {
      font-size: 16px;
      line-height: 145%;
    }

    &__footer-policy {
      font-size: 15px;
    }
  }
}