.footer {
  background: var(--violet);

  &__socials-list {
    display: flex;
    column-gap: 8px;
  }

  &__rclass-logo-wrapper {
    margin-top: 22px;
    display: flex;
  }

  &__rclass-logo {
    display: flex;
  }

  &__about-list {
    display: flex;
    flex-direction: column;
  }

  &__about-item {
    display: flex;
    align-items: center;
  }

  &__policy-wrap {
    display: flex;
  }

  &__social-item {
    margin: -4px;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--arrow-size);
    height: var(--arrow-size);
    margin-left: var(--arrow-margin);

    svg {
      width: inherit;
      height: inherit;
    }
  }

  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }

  &__social-icon {
    width: 30px;
    height: 30px;
  }

  &__about-list-wrapper {
    pointer-events: none;
  }

  &__about-item {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;

    svg {
      width: 100%;
      height: auto;
    }

    &.swiper-slide-next {
      opacity: 1;
    }
  }

  &__about-list-wrapper {
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .footer {
    padding: 170px 0 60px;

    &__about-item {
      height: 33px;
    }

    &__about-list-wrapper {
      margin-top: 16px;
      height: calc(3 * 33px + 2 * 16px);
    }

    &__wrap {
      &.grid-block {
        grid-row-gap: 130px;
      }
    }

    &__rclass {
      grid-column: 1/6;
    }

    &__policy-wrap {
      flex: 0 0 calc(var(--grid-column5) + var(--grid-gap));
    }
  }
}

@include respond-up('medium') {
  .footer {
    --arrow-size: 25px;
    --arrow-margin: 16px;

    &__about-list-wrapper {
      grid-column: 7/13;
    }

    &__bottom-wrap {
      grid-column: 1/13;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__policy-wrap {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 130px 0 48px;

    &__about-item {
      height: 24px;
    }

    &__about-list-wrapper {
      margin-top: 37px;
      height: calc(3 * 24px + 2 * 16px);
    }

    &__wrap {
      &.grid-block {
        grid-row-gap: 100px;
      }
    }

    &__rclass {
      grid-column: 1/7;
    }

    &__about-item {
      height: 21px;
    }

    &__policy-wrap {
      flex: 0 0 calc(var(--grid-column7));
    }

    &__rclass-logo {
      svg {
        width: 280px;
        height: auto;
      }
    }
  }
}

@include respond-down('small') {
  .footer {
    --arrow-size: 20px;
    --arrow-margin: 8px;

    padding: 80px 0 40px;

    &__wrap {
      &.grid-block {
        grid-template-columns: 1fr;
        grid-row-gap: 0;
      }
    }

    &__rclass {
      padding-left: 5px;
    }

    &__rclass-logo {
      svg {
        width: 261px;
        height: auto;
      }
    }

    &__about-list-wrapper {
      margin-top: 50px;
      height: calc(3 * 19px + 2 * 15px);
    }

    &__about-item {
      height: 21px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__bottom-wrap {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 60px;
    }

    &__policy-wrap {
      flex-direction: column;
      margin-top: 30px;
    }

    &__policy {
      margin-top: 9px;
    }
  }
}