.header {
  width: 100%;

  &__wrap {
    background: #fff;
    height: var(--header-height);
    position: fixed;
    display: flex;
    z-index: 15000;
  }

  &__content-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__logo,
  &__hamburger {
    padding: 10px;
    margin: -10px;
  }
}

@include respond-up('s-large') {
  .header {
    &__wrap {
      width: 788px;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      border-radius: 10px;
      box-shadow: 0 4px 30px rgba(114, 112, 122, 0.42);
      padding: 7px 14px 7px 30px;
    }
  }
}

@include respond-up('medium') {
  .header {
    &__hamburger,
    &__drop-menu {
      display: none;
    }

    &__list {
      display: flex;
      column-gap: 18px;
    }
  }
}

@include respond('medium') {
  .header {
    &__content-wrap {
      padding: 7px var(--grid-spacer);
    }
  }
}

@include respond-down('medium') {
  .header {
    height: var(--header-height);
    &__wrap {
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

@include respond-down('small') {
  .header {
    &__content-wrap {
      padding: 15px var(--grid-spacer);
      position: relative;
    }

    &__logo {
      svg {
        width: 68px;
        height: 22px;
      }
    }

    &__list,
    &__button-wrap {
      display: none;
    }

    &__hamburger {
      display: flex;
      &._opened {
        .header__hamburger-lines {
          &:before,
          &:after {
            width: 20px;
          }

          &:before {
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }

          &:after {
            bottom: 50%;
            transform: translateY(50%) rotate(-45deg);
          }
        }
      }
    }

    &__hamburger-lines {
      width: 36px;
      height: 9px;
      display: flex;
      position: relative;

      &:before,
      &:after {
        position: absolute;
        content: '';
        right: 0;
        width: 100%;
        height: 2px;
        background: var(--black-color);
        transform-origin: center;
        transition-property: top, bottom, width, transform;
        transition-duration: .2s;
        transition-timing-function: var(--default-transition-function);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }
}