.widget-form-checkbox {
    input {
        display: none;
    }
    label {
        position: relative;
        display: block;
        width: 34px;
        height: 14px;
        background: rgba(34, 31, 31, 0.26);
        border-radius: 10px;
        transition: background .5s;
        &:before{
            transition-property: background, transform;
            transition-duration: .5s;
            position: absolute;
            left: 0;
            bottom: -3px;
            content: '';
            border-radius: 100%;
            width: 20px;
            height: 20px;
            background: #F1F1F1;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
        }
    }

    input:checked + label{
        background: var(--violet-light);
        &:before{
            background: var(--violet);
            transform: translateX(15px);
        }
    }
}