.index-slider {
  &__icon-wrap {
    display: flex;
  }

  &__heading {
    display: flex;
    justify-content: center;
  }

  &__heading-text {
    text-align: center;
  }

  &__slide {
    border-radius: 20px;

    &:nth-child(4n - 3) {
      background: var(--grey-background);
    }

    &:nth-child(4n - 2) {
      background: #E9E6FB;
    }

    &:nth-child(4n - 1) {
      background: #DFEDFB;
    }

    &:nth-child(4n) {
      background: #F4F4FD;
    }
  }

  &__slide-content {
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .index-slider {
    margin-top: 20px;

    &__slides {
      height: 699px;
    }

    &__heading-text {
      width: var(--grid-column8);
    }

    &__wrap {
      position: sticky;
      top: 20vh;
    }

    &__slide-title,
    &__slide-text,
    &__button-wrap,
    &__icon-wrap {
      overflow: hidden;
    }

    &__slider {
      &._scroller {
        .index-slider {
          transform: translate3d(0, 0, 0);

          &__slides-container {
            height: calc(100vh * var(--slides-count));
            position: relative;
          }

          &__slider-wrap {
            margin-top: 32px;
            position: relative;
          }

          &__slides {
            position: sticky;
            top: 0;
            overflow: hidden;
          }

          &__slide {
            position: absolute;
            height: 100%;
            overflow: hidden;
          }

          &__slide-content {
            position: relative;
            display: flex;
          }

          &__slide-image {
            overflow: hidden;
          }

          &__picture, &__image {
            display: block;
          }

          &__slide {
            transition: all 0.1s;

            transform: translateY(calc(-1 * var(--percent)));


            // Нахер верстальщикам математика?
            // Не нужна. Пойду просто верстать.
            // Блоки там всякие
            // Мы чо на уроке математики чтоли?
            // Да не, бред какой-то

            --scale: calc(1 + (0.2 - var(--next-percent-val) * 0.002));
            --offset: calc(300px - (var(--next-percent-val) / 100 * 300px));
            overflow: hidden;
          }

          &__slide {
            .index-slider__slide-title-text,
            .index-slider__slide-description,
            .index-slider__button,
            .index-slider__icon-wrap svg {
              animation-fill-mode: forwards;
              animation-duration: 0.5s;
              transform: translateY(-100%);
              opacity: 0;
            }

            &._animate-in {
              .index-slider__slide-title-text,
              .index-slider__slide-description,
              .index-slider__button,
              .index-slider__icon-wrap svg {
                animation-name: scroller-active-down;
              }
            }
          }
        }
      }
    }

    &__slide-content {
      column-gap: var(--grid-gap);
      padding: 50px calc(var(--grid-gap) + var(--grid-column)) 50px var(--grid-column);
    }

    &__slide-content-text {
      padding-top: 40px;
      flex: calc(var(--grid-gap) + var(--grid-column4));
    }

    &__slide-image {
      flex: var(--grid-column6);
    }

    &__bullets-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 20;
    }

    &__bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #DADADA;
      transition: background var(--default-timing) var(--default-transition-function);

      &._active {
        background: #8268E9;
      }
    }

    &__picture {
      height: calc(var(--grid-column6) * 599 / 584);
    }
  }
}

@include respond-up('medium') {
  .index-slider {
    &__slide-content {
      align-items: flex-start;
    }

    &__slide-title {
      margin-top: 40px;
    }

    &__slide-text {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .index-slider {
    margin-top: 100px;

    &__slider-wrap {
      margin-top: 25px;
    }

    &__slides {
      row-gap: 20px;
    }

    &__slide-content {
      column-gap: 10px;
      padding: 80px 20px 80px 30px;
    }

    &__slide-content-text {
      flex: 0 0 calc(var(--grid-column5) - 12px);
    }

    &__slide-image {
      flex: 0 1 100%;
    }

    &__picture {
      height: calc((var(--grid-column7) - 30px) * 424 / 414);
    }
  }
}

@include respond-down('medium') {
  .index-slider {
    &__slides {
      display: flex;
      flex-direction: column;
    }

    &__bullets-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .index-slider {
    margin-top: 20px;

    &__slider-wrap {
      margin-top: 22px;
    }

    &__slides {
      row-gap: 10px;
    }

    &__slide-content {
      flex-direction: column;
      row-gap: 10px;
    }

    &__slide-content-text {
      padding: 30px 25px 0;
      width: 100%;
    }

    &__slide-image {
      width: 100%;
    }

    &__slide-title {
      margin-top: 20px;
    }

    &__slide-text {
      margin-top: 16px;
    }

    &__button-wrap {
      margin-top: 25px;
    }

    &__picture {
      height: calc(var(--grid-column10) * 342 / 334);
    }
  }
}

$offsetMove: 100%;
@keyframes scroller-active-up {
  from {
    transform: translate3d(0, -$offsetMove, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes scroller-active-down {
  from {
    transform: translate3d(0, $offsetMove, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes scroller-inactive-up {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, $offsetMove, 0);
    opacity: 0;
  }
}

@keyframes scroller-inactive-down {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -$offsetMove, 0);
    opacity: 0;
  }
}