.widget-form {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-title {
    text-align: center;
  }

  &__container {
    background: #FFFFFF;
    box-shadow: 0 10px 40px rgba(175, 174, 180, 0.45);
    border-radius: 10px;

    &_result {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &_message {
      .widget-form {
        &__title {
          margin: 0 !important;
        }
      }
    }
  }

  &__title {
    display: block;
  }

  &__field-name {
    @extend .text__sixteen;
    font-weight: 500;
    color: var(--black-text);
    transition: color .3s;

    &._checked {
      color: var(--violet);
    }
  }

  &__result-description {
    @extend .text__field-label;
    color: var(--grey-text);
  }

  &__label, &__radio-block-label {
    color: var(--grey-text);
  }

  &__code {
    background: #F2F3FB;
    border-radius: 6px;
    width: 100%;
    height: fit-content;
    white-space: pre;
    overflow: hidden;
  }

  &__field-head {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 219px;
    cursor: pointer;
    //justify-content: space-between;
    &:hover {
      .widget-form {
        &__hint-modal {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column-reverse;

    input[type='text'] {
      background: var(--grey-background);
      border: 1px solid var(--stroke);
      border-radius: 4px;
      padding: 11px 18px;
      opacity: 1;
      transition: opacity .5s;

      &._hasError {
        border: 1px solid crimson;
      }
    }

    input[type='text'] + label {
      opacity: 1;
      transition: opacity .5s;
    }

    input[type='text']:disabled + label {
      opacity: 0.5;
    }
  }

  &__field-radio {
    input[type="radio"] {
      display: none;
    }

    input[type='radio'] + label {
      position: relative;
      padding-left: 31px;
      margin-right: 30px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid var(--stroke);
        border-radius: 100%;
      }
    }

    input[type='radio']:checked + label {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 8px;
        border: 6px solid var(--violet);
        border-radius: 100%;
      }
    }
  }

  &__radio-block {
    display: flex;
  }

  &__example-block {
    display: flex;
  }

  &__example-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    margin-right: 35px;
  }

  &__example-icon {
    background-image: url('data:image/svg+xml,<svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5337 9.126C12.2234 8.383 11.9882 8.35475 11.5182 8.33575C11.3582 8.3265 11.1799 8.31725 10.9822 8.31725C10.3707 8.31725 9.73141 8.496 9.34566 8.891C8.87566 9.37075 7.70941 10.49 7.70941 12.785C7.70941 15.0803 9.38316 17.3 9.60891 17.6108C9.84416 17.9208 12.8722 22.6993 17.5739 24.6468C21.2507 26.1705 22.3417 26.0293 23.1784 25.8508C24.4009 25.5875 25.9337 24.684 26.3194 23.5933C26.7049 22.502 26.7049 21.5708 26.5919 21.3733C26.4792 21.1758 26.1687 21.0633 25.6987 20.8278C25.2287 20.5925 22.9434 19.4638 22.5109 19.3133C22.0877 19.1535 21.6834 19.21 21.3639 19.6615C20.9124 20.2918 20.4707 20.9315 20.1132 21.317C19.8312 21.618 19.3702 21.6558 18.9847 21.4958C18.4674 21.2798 17.0194 20.7713 15.2327 19.1818C13.8502 17.9498 12.9099 16.4168 12.6374 15.9558C12.3647 15.4855 12.6092 15.2125 12.8254 14.9588C13.0607 14.667 13.2862 14.4603 13.5212 14.1873C13.7564 13.9145 13.8879 13.7735 14.0384 13.4535C14.1984 13.1433 14.0854 12.8233 13.9727 12.588C13.8597 12.3523 12.9194 10.057 12.5337 9.126ZM16.9994 1.00012C8.1789 1.00012 1.00415 8.17687 1.00415 16.9999C1.00415 20.4989 2.13265 23.7441 4.0509 26.3779L2.05715 32.3226L8.20715 30.3571C10.7366 32.0314 13.7551 33.0001 17.0089 33.0001C25.8294 33.0001 33.0041 25.8229 33.0041 17.0004C33.0041 8.17737 25.8294 1.00062 17.0089 1.00062H16.9996V1.00012H16.9994Z" fill="%234BCA59"/></svg>');
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);

    &_small {
      background-size: 22px 22px;
      width: 48px;
      height: 48px;
    }

    &_medium {
      background-size: 27px 27px;
      width: 58px;
      height: 58px;
    }

    &_large {
      background-size: 32px 32px;
      width: 68px;
      height: 68px;
    }
  }

  &__example-size {
    @extend .text__sixteen;
    color: var(--black-color);
    margin-top: 10px;
  }

  &__example-size-px {
    @extend .text__field-label;
    color: var(--grey-text);
    font-weight: 500;
    margin-top: 2px;
  }

  &__notify {
    @extend h4;
    color: var(--violet);
    position: absolute;
    font-size: 16px;
    opacity: 0;
    transition: opacity .4s;

    &._show {
      opacity: 1;
    }
  }

  &__hint {
    @extend .text__sixteen;
    font-weight: 700;
    color: var(--violet);
    transition: color .3s;
    display: flex;
    width: 15px;
    height: 15px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--violet-super-light);
    margin-left: 4px;
    cursor: pointer;
  }

  &__hint-modal {
    position: absolute;
    transition: opacity .3s;
    pointer-events: none;
    opacity: 0;
    max-width: 235px;
    background: #F5F5F5;
    border: 1px solid var(--stroke);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px 8px;
    top: 30px;
    left: 0;
    z-index: 1000;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    color: var(--black-color);
  }
}

@include respond-up('s-large') {
  .widget-form {
    margin-top: 130px;

    &__example-block {
      top: 35px;
      right: var(--grid-column4);
    }

    &__fields {
      grid-column-gap: 110px;
      grid-template-columns: 1fr 1fr;

      &_position {
        grid-template-columns: calc(var(--grid-column4) - 40px) var(--grid-column2) var(--grid-column2) auto;
      }

      &_message {
        grid-template-columns: 850px 1fr;
        grid-column-gap: 28px;
      }
    }
  }
}

@include respond-up('medium') {
  .widget-form {
    &__example-block {
      position: absolute;
    }

    &__container {
      padding: 35px 40px;
      margin-top: 20px;

      &_links {
        margin-top: 36px;
      }

      &_size {
        padding: 35px 40px 62px;
        position: relative;
      }
    }

    &__title, &__head {
      margin-bottom: 24px;
    }

    &__fields {
      display: grid;
      grid-row-gap: 42px;

      &_position {
        grid-column-gap: var(--grid-gap);
      }

      &_message {
        grid-template-columns: calc(var(--grid-column9) - 40px) calc(var(--grid-column3) - 40px);
      }
    }

    &__field-container {
      display: flex;
      flex-direction: column;
    }

    &__field-head {
      margin-bottom: 16px;
    }

    &__label {
      margin-bottom: 10px;
    }

    &__result-description {
      font-size: 15px;
      margin-bottom: 14px;
    }

    &__code {
      min-height: 243px;
      padding: 20px;
    }

    &__radio-block {
      height: 35px;
      display: flex;

      &_position {
        margin-top: 15px;
      }

      &_size {
        margin-top: 24px;
      }
    }

    &__copy-button {
      width: 195px;
      align-self: flex-end;
      margin-top: 24px;
    }

    &__notify {
      right: 270px;
      bottom: 50px;
    }
  }
}

@include respond('medium') {
  .widget-form {
    margin-top: 100px;

    &__example-block {
      right: 0;
      top: 35px;
    }

    &__fields {
      grid-template-columns: var(--grid-column9);

      &_message {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;

        .widget-form__field-container {
          &:nth-child(2) {
            width: var(--grid-column4);
          }
        }
      }

      &_position {
        grid-column-gap: 28px;
        grid-template-columns: calc(var(--grid-column5) - 40px) var(--grid-column3) var(--grid-column3);
      }
    }

    &__field {
      &_message-timeout {
        max-width: 238px;
      }
    }
  }
}

@include respond-down('medium') {
  .widget-form {

  }
}

@include respond-down('small') {
  .widget-form {
    margin-top: 50px;

    &__container {
      padding: 25px 25px;
      margin-top: 12px;

      &_links {
        margin-top: 20px;
      }
    }

    &__title, &__head {
      margin-bottom: 20px;
    }

    &__field-head {
      margin-bottom: 20px;
    }

    &__fields {
      display: grid;
      grid-row-gap: 28px;

      &_position {
        margin-top: 20px;
      }
    }

    &__label {
      margin-bottom: 10px;
    }

    &__radio-block-label {
      margin-bottom: 15px;
    }

    &__example-block {
      margin-top: 30px;
    }

    &__copy-button {
      margin-top: 16px;
    }

    &__result-description {
      margin-bottom: 15px;
    }

    &__code {
      min-height: 243px;
      height: fit-content;
      padding: 20px;
    }

    &__notify {
      top: 50%;
      left: 45px;
      right: 45px;
      text-align: center;
      background-color: #FFFFFF;
      padding: 10px;
      border-radius: 16px;
    }
  }
}