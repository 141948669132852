:root {
  --default-input-height: 58px;
  --errors-color: var(--red)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

form {

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: var(--black-color);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  label {
    color: var(--black-color);
    display: block;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.02em;
    //position: absolute;
    //top: 50%;
    //transform: translateY(-50%);
    //left: 25px;
  }

  ul.errors {
    margin-top: 10px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 0;
    border-radius: 6px;
    box-shadow: none;
    margin: 0;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary-color);
    width: 16px;
    height: 16px;
    border: 1px solid var(--violet);
    border-radius: 50%;
    display: inline-grid;
    place-content: center;
    transition-property: background, border-color, background-image;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      transition: transform var(--default-timing) var(--default-transition-function);
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }

    &:checked {
      background: var(--violet);
      border-color: var(--violet);
      &::before {
        background-image: url('../images/svg/checkbox-checked.svg');
      }
    }
  }
}

@include respond-up('s-large') {
  form {

  }
}

@include respond-up('medium') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 18px;
    }

    #{$inputs}, textarea, select {
      padding: 0 30px 0 25px;
    }
  }
}

@include respond-down('small') {
  form {
    --default-input-height: 51px;

    #{$inputs}, textarea, select, label {
      font-size: 16px;
    }

    #{$inputs}, select {
      height: var(--default-input-height);
    }

    #{$inputs}, textarea, select {
      padding: 0 15px 0 25px;
    }
  }
}