@include respond-down('small') {
  .drop-menu {
    position: absolute;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    left: 0;
    right: 0;
    background: #fff;
    padding: 15px var(--grid-spacer) 24px;
    transition: opacity var(--default-timing) var(--default-transition-function);

    &._opened {
      opacity: 1;
      pointer-events: auto;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      column-gap: var(--grid-gap);
      row-gap: 15px;
    }

    &__item {
      flex: 0 0 var(--grid-column5);
    }

    &__button-wrap {
      margin-top: 25px;
    }
  }
}