.index-rates {
  &__heading {
    text-align: center;
  }

  &__item {
    background: #fff;
    box-shadow: 0 10px 40px rgba(175, 174, 180, 0.45);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    display: flex;
    border-bottom: var(--stroke-border-style);
    align-items: center;
    justify-content: space-between;
  }

  &__item-icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__service-item {
    display: flex;
  }

  &__item-services {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex: 1 1 auto;
  }

  &__service-item {
    display: flex;
    align-items: flex-start;
  }

  &__service-icon {
    &_premium {
      svg path {
        stroke: var(--yellow);
      }
    }
  }

  &__service-name {
    margin-left: 13px;
  }

  &__button {
    width: 100%;
  }
}

@include respond-up('s-large') {
  .index-rates {
    &__list {
      margin-top: 36px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}

@include respond-up('medium') {
  .index-rates {
    &__item {
      padding: 30px;
    }

    &__item-title {
      padding-bottom: 25px;
    }

    &__item-services {
      margin-top: 25px;
    }

    &__button-wrap {
      margin-top: 50px;
    }

    &__button {
      &.button_yellow {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

@include respond('medium') {
  .index-rates {
    &__list {
      margin-top: 25px;
    }

    &__item {
      grid-column: span 6;
    }
  }
}

@include respond-down('medium') {
  .index-rates {

  }
}

@include respond-down('small') {
  .index-rates {
    &__list {
      margin-top: 22px;
    }

    &__item {
      padding: 25px;
      grid-column: span 10;
    }

    &__item-title {
      padding-bottom: 20px;
    }

    &__item-services {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__button {
      &.button_yellow {
        padding-top: 13px;
        padding-bottom: 13px;
      }
    }

    &__item-icon {
      width: 20px;
      height: 18px;
    }
  }
}